<template>
  <div class="content">
    <el-form inline :model="search" class="tb-form">
      <el-form-item label="品牌名称：">
        <el-input v-model="search.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="营业状态：">
        <el-select v-model="search.status">
          <el-option :label="item.label" :value="item.id" v-for="item in statusOptions" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分类：">
        <el-cascader :options="categoryOptions" v-model="search.categroy"></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="add">
      <el-button class="tb-button" type="primary" @click="add">
        <i class="tb-icon-plus"/>添加品牌
      </el-button>
    </div>
    <div class="tablebox">
      <el-table
        :data="tableData"
        class="tb-table"
        @row-dblclick="toDetail"
      >
        <el-table-column prop="id" label="品牌ID" />
        <el-table-column prop="brand_name" label="品牌名称" min-width="100px" />
        <el-table-column label="品牌logo">
          <template slot-scope="scope">
            <img :src="scope.row.logo" width="37px" alt="logo">
          </template>
        </el-table-column>
        <el-table-column label="品牌简介" min-width="200px">
          <template slot-scope="scope">
            <el-popover
              width="500"
              placement="top"
              trigger="hover"
              :content="scope.row.brand_desc"
            >
              <div slot="reference" class="ellipsis-two">{{scope.row.brand_desc}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="营业状态">
          <template slot-scope="scope">
            <el-select placeholder="选择" v-model="scope.row.status" @change="statusChange(scope.row)" style="width: 84px;">
              <el-option label="营业" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="last_modifier" label="创建用户"/>
        <el-table-column prop="date" label="创建时间"/>
        <el-table-column label="操作">
          <router-link slot-scope="scope" :to="`/brand/add?id=${scope.row.id}`" class="edit">编辑</router-link>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="tb-pagination"
        layout="prev, pager, next"
        :current-page.sync="page.no + 1"
        :page-size="page.size"
        :total="page.total"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'BrandList',
  data() {
    return {
      search: {
        name: '',
        status: '',
        categroy: ''
      },
      statusOptions: [{
        id: 0,
        label: '未营业'
      }, {
        id: 1,
        label: '营业中'
      }],
      tableData: [],
      page: {
        no: 0,
        size: 10,
        total: 0
      }
    }
  },
  computed: mapState([
    'categoryOptions'
  ]),
  mounted() {
    let path = this.$route.path
    if (path.indexOf('/') === 0) path = path.substring(1)
    const id = this.$route.query.id * 1 || null
    const currentPage = {
      link: path,
      name: '品牌列表'
    }
    if (id) currentPage.name = '编辑品牌信息'
    this.$store.commit('changeRoute', currentPage)
    this.getList()
  },
  methods: {
    onSearch() {
      this.page.no = 0
      this.getList()
    },
    add() {
      this.$router.push('/brand/add')
    },
    async getList() {
      let {no, size} = this.page
      let {name, status, categroy} = this.search
      const categoryid = Array.isArray(categroy) ? categroy[2] : ''
      const params = {
        name,
        status,
        category_id: categoryid,
        page_size: size,
        page_no: no
      }
      const url = 'admin/brand/list'
      const data = await this.$https.get(url, {params})
      if (!data) return
      data.list.forEach(item => {
        item.logo = this.$img(item.logo_url)
        item.date = this.$dayjs(item.created_at).format('YYYY-MM-DD')
      })
      this.page.total = data.total_count
      this.tableData = data.list
    },
    pageChange(no) {
      this.page.no = no - 1
      this.getList()
    },
    statusChange(item) {
      this.$confirm('确定要进行此操作吗?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false
      }).then(() => {
        const url = 'admin/brand/edit'
        const params = Object.assign({}, item)
        this.$https.post(url, params).then(data => {
          if (!data) return
          this.$message.success('修改成功')
        })
      }).catch(() => {
        item.status = item.status === 0 ? 1 : 0
      })
    },
    toDetail(row) {
      this.$router.push(`/brand/add?id=${row.id}`)
    }
  }
}
</script>
